<template>
  <div style="width:100%;height:100%">
    <div id="viewer"></div>
    <div class="markContainer">
      <Icon type="ios-arrow-dropleft-circle"
            size='36'
            class="ico"
            title="退回"
            @click="back" />
      <Icon type="ios-radio-button-on"
            @click="markerTypeMark = 'circle'"
            title="圆形"
            class="ico"
            size="36" />
      <Icon type="md-pin"
            @click="markerTypeMark =2"
            title="定位"
            class="ico"
            size="36" />
      <Icon type="md-pricetag"
            @click="markerTypeMark ='text'"
            title="文本"
            class="ico"
            size="36" />
      <Icon type="md-share-alt"
            @click="markerTypeMark ='guide'"
            title="全景切换"
            class="ico"
            size="36" />
      <Icon type="md-checkmark"
            class="ico"
            title="保存"
            @click="saveBtn"
            size="36" />
    </div>
    <Modal v-model="markerInfomodal"
           title="标注"
           @on-ok="markerInfomodalOk"
           @on-cancel="markerInfomodalCancel">
      <Form ref="formValidate"
            :model="formValidate"
            :rules="ruleValidate"
            :label-width="80">
        <FormItem label="提示"
                  prop="tooltip">
          <Input v-model="formValidate.tooltip"
                 placeholder="提示信息"></Input>
        </FormItem>
        <FormItem label="文本"
                  v-if="markerTypeMark == 'text'|| markerTypeMark == 'guide'"
                  prop="html">
          <Input v-model="formValidate.html"
                 placeholder="默认文本"></Input>
        </FormItem>
        <FormItem label="内容"
                  v-if="markerTypeMark!='guide'"
                  prop="content">
          <Input v-model="formValidate.content"
                 type="textarea"
                 placeholder="详细内容"></Input>
        </FormItem>
        <FormItem label="全景切换"
                  v-if="markerTypeMark=='guide'"
                  prop="city">
          <Select v-model="formValidate.panoramaImgId"
                  clearable
                  placeholder="请选择">
            <Option v-for="item in categoryList"
                    :key="item.id"
                    :value="item.id">{{item.name}}</Option>
          </Select>
        </FormItem>
      </form>

      <div slot="footer">
        <Button type="info"
                @click="markerInfomodalCancel">取消</Button>
        <Button type="info"
                @click="markerInfomodalOk">应用</Button>
        <Button type="error"
                @click="markerInfomodalDel">删除</Button>
      </div>

    </Modal>
  </div>
</template>

<script>
import { Viewer, Animation } from 'photo-sphere-viewer';
import MarkersPlugin from 'photo-sphere-viewer/dist/plugins/markers';
import 'photo-sphere-viewer/dist/photo-sphere-viewer.css';
import 'photo-sphere-viewer/dist/plugins/markers.css';
import { PanoramawebUpdate, PanoramawebDetail } from '@/utils/javaApi.js';
export default {
  name: 'EdtiPanorama',
  props: [],
  data() {
    return {
      caption: '',
      panoramaInfo: {},
      categoryList: [], // 要跳转的图片集合
      guidICon: '',
      id: 0,
      imgPath: '',
      viewer: null,
      markersPlugin: null,
      autorotatePlugin: null,
      markers: [],
      markerConfig: {},
      point: {},
      markerInfomodal: false,
      markerTypeMark: 'circle',
      markerType: {
        image: 'https://photo-sphere-viewer.js.org/assets/pin-blue.png',
        circle: 20, // 1是圆
      },
      formValidate: {
        // id: '',
        tooltip: '',
        panoramaImgId: 0,
        panoramaImgPath: '',
        content: '',
        html: '默认文本',
        // markerTypeMark: 'circle',
      },
      tempPoint: {},
      ruleValidate: {
        tooltip: [
          {
            required: true,
            message: 'The tooltip cannot be empty',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.guidICon =
      '<img class="guideIcon" src="http://129.204.9.15:9024/data/wwwroot/joint_flight_java/upload_test/234/1.gif">';
    this.getDetail();
  },
  mounted() {},
  watch: {},
  methods: {
    back() {
      this.$router.go(-1);
    },
    delProp(el) {
      let type = el.markerTypeMark;
      switch (type) {
        case 'text':
          el.html = el.html2;
          delete el.html2;
          delete el.style;
          break;
        case 'circle':
          delete el.style;
          delete el.html2;
          delete el.svgStyle;
          break;
        case 'guide':
          el.html = el.html2;
          delete el.html2;
          delete el.style;
          break;
        case '2':
          delete el.html;
          delete el.html2;
          delete el.style;
          break;
        default:
          break;
      }
    },
    formatMarke(el) {
      let type = el.markerTypeMark;
      switch (type) {
        case 'text':
          el.style = {
            maxWidth: '320px',
            color: '#fff',
            fontSize: '20px',
            fontFamily: 'Helvetica, sans-serif',
            textAlign: 'center',
          };
          el.html2 = el.html;
          break;
        case 'circle':
          el.svgStyle = {
            fill: 'rgba(255,255,0,0.3)',
            stroke: 'yellow',
            strokeWidth: '2px',
          };
          break;
        case 'guide':
          el.style = {
            maxWidth: '320px',
            color: '#fff',
            fontSize: '20px',
            fontFamily: 'Helvetica, sans-serif',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          };
          el.html2 = el.html;
          el.html = el.html + this.guidICon;
          //   delete el.html2;
          break;
        default:
          break;
      }
    },
    async getDetail() {
      let res = await this.$post(PanoramawebDetail(), { panorama_id: this.id });
      if (res.code === 1) {
        let result = res.data.filter((el) => el.id == this.id)[0];
        this.imgPath = result.panoramaImgPath;
        this.caption = result.name;
        this.init();
        this.viewer.once('ready', this.setPoints);
        // this.viewer.once('ready', this.intro);
        this.categoryList = res.data.filter((el) => el.pid != 0);
        result.markers.map((el) => {
          if (el.markerTypeMark == 'circle') {
            delete el.image;
            delete el.html;
          }
          if (el.markerTypeMark == 'guide') {
            delete el.image;
            delete el.circle;
          }
          if (el.markerTypeMark == 'text') {
            delete el.image;
            delete el.circle;
          }
          if (el.markerTypeMark == '2') {
            delete el.circle;
            delete el.html;
          }
        });
        console.log(result.markers);
        this.markers = result.markers;
      } else {
        this.$Message.error(res.msg_customer);
      }
    },
    setPoints() {
      this.markers.forEach((el) => {
        this.formatMarke(el);
        this.markersPlugin.addMarker(el);
      });
    },

    async saveBtn() {
      this.markers.map((el) => {
        this.delProp(el);
      });
      let params = {
        id: this.id,
        markers: JSON.stringify(this.markers),
        // markers: JSON.stringify([]),
      };

      console.log('保存的数据', this.markers);
      //   return;
      let res = await this.$post(PanoramawebUpdate(), params);
      if (res.code === 1) {
        this.$Message.success(res.msg_customer);
        this.$router.go(-1);
      } else {
        this.$Message.error(res.msg_customer);
      }
    },

    markerInfomodalOk() {
      if (
        this.formValidate.panoramaImgId != 0 &&
        this.markerTypeMark == 'guide'
      ) {
        this.point.panoramaImgPath = this.categoryList.filter(
          (el) => el.id == this.formValidate.panoramaImgId
        )[0].panoramaImgPath;
      }
      let i = this.markers.findIndex((el) => el.id == this.point.id);
      console.log('<<<<<<', this.markers[i]);

      this.markers[i].tooltip = this.formValidate.tooltip;

      if (this.markerTypeMark == 'text') {
        this.markers[i].html = this.formValidate.html;
        this.markers[i].html2 = this.formValidate.html;
        this.markers[i].content = this.formValidate.content;

        this.markersPlugin.updateMarker({
          id: this.point.id,
          tooltip: this.formValidate.tooltip,
          content:
            '<div class="rightContent">' + this.formValidate.content + '</div>',
          html: this.formValidate.html,
        });
      }
      if (this.markerTypeMark == 'guide') {
        this.markers[i].content = '';
        this.markers[i].html2 = this.formValidate.html;
        this.markers[i].html = this.formValidate.html;
        console.log('点确定时的html', this.formValidate.html);
        let picIndex = this.categoryList.findIndex(
          (el) => el.id == this.formValidate.panoramaImgId
        );
        this.markers[i].panoramaImgId = this.formValidate.panoramaImgId;
        if (this.formValidate.panoramaImgId != 0) {
          this.markers[i].panoramaImgPath =
            this.categoryList[picIndex].panoramaImgPath;
          this.markers[i].name = this.categoryList[picIndex].name;
        }

        this.markersPlugin.updateMarker({
          id: this.point.id,
          tooltip: this.formValidate.tooltip,
          html: this.formValidate.html + this.guidICon,
        });
      }
      if (this.markerTypeMark == 'circle') {
        this.markers[i].content = this.formValidate.content;
        this.markersPlugin.updateMarker({
          id: this.point.id,
          tooltip: this.formValidate.tooltip,
          content:
            '<div class="rightContent">' + this.formValidate.content + '</div>',
        });
      }

      if (this.markerTypeMark == '2') {
        this.markers[i].content = this.formValidate.content;
        console.log(2222222);
        this.markersPlugin.updateMarker({
          id: this.point.id,
          tooltip: this.formValidate.tooltip,
          content:
            '<div class="rightContent">' + this.formValidate.content + '</div>',
        });
      }

      console.log('加了信息的数组', this.markers);
      this.markerInfomodal = false;
    },
    markerInfomodalCancel() {
      this.formValidate.tooltip = '';
      //   this.formValidate.content = '';
      this.formValidate.html = '默认文本';
      this.formValidate.panoramaImgId = 0;

      console.log('取消时的tempPoint', this.tempPoint);
      this.markerInfomodal = false;
    },
    markerInfomodalDel() {
      let index = this.markers.findIndex((el) => el.id == this.point.id);
      console.log('索引是', index);
      this.markers.splice(index, 1);
      console.log('删除后的数组', this.markers);
      this.markersPlugin.removeMarker(this.point.id);
      this.markerInfomodal = false;
      this.formValidate.html = '默认文本';
    },
    addMarkers() {
      alert('hi');
    },
    handleMarkerClick() {
      this.markersPlugin.on('select-marker', (e, marker, data) => {
        // var a = this.viewer.getPosition();

        if (!data.dblclick) {
          this.point = marker.config;
          console.log('点击的点', this.point);
          this.markerTypeMark = this.point.markerTypeMark;
          let i = this.markers.findIndex((el) => el.id == this.point.id);
          console.log('点击的点2', this.markers[i]);
          this.point.tooltip
            ? (this.formValidate.tooltip = this.point.tooltip.content)
            : '';
          this.point.panoramaImgPath
            ? (this.formValidate.panoramaImgPath = this.point.panoramaImgPath)
            : '';
          this.markers[i].html2
            ? (this.formValidate.html = this.markers[i].html2)
            : '';
          this.markers[i].content
            ? (this.formValidate.content = this.markers[i].content)
            : '';
          this.markers[i].panoramaImgId
            ? (this.formValidate.panoramaImgId = this.markers[i].panoramaImgId)
            : 0;

          this.markerInfomodal = true;
        }
      });
    },
    handleViewerClick() {
      let that = this;

      this.viewer.on('click', function (e, data) {
        that.formValidate.content = '';
        that.formValidate.tooltip = '';
        that.formValidate.panoramaImgId = '';
        that.formValidate.panoramaImgPath = '';
        that.formValidate.html = '默认文本';
        console.log('click--html', that.formValidate.html);
        that.point = {
          id: Math.floor(Math.random() * 1000) + 1 + '',
          longitude: data.longitude,
          latitude: data.latitude,
          anchor: 'bottom center',
        };

        if (that.markerTypeMark == 'circle') {
          that.point.circle = 20;
          that.point.markerTypeMark = 'circle';
          that.point.svgStyle = {
            fill: 'rgba(255,255,0,0.3)',
            stroke: 'yellow',
            strokeWidth: '2px',
          };
          that.tempPoint = JSON.parse(JSON.stringify(that.point));
          delete that.tempPoint.svgStyle;
        }

        if (that.markerTypeMark == 'text') {
          that.point.markerTypeMark = 'text';
          that.point.html = that.formValidate.html;
          that.point.style = {
            maxWidth: '320px',
            color: '#fff',
            fontSize: '20px',
            fontFamily: 'Helvetica, sans-serif',
            textAlign: 'center',
          };
          that.tempPoint = JSON.parse(JSON.stringify(that.point));
          delete that.tempPoint.style;
        }

        if (that.markerTypeMark == 'guide') {
          that.point.markerTypeMark = 'guide';
          that.point.html = that.formValidate.html + that.guidICon;

          that.point.style = {
            maxWidth: '320px',
            color: '#fff',
            fontSize: '20px',
            fontFamily: 'Helvetica, sans-serif',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          };
          that.tempPoint = JSON.parse(JSON.stringify(that.point));
          delete that.tempPoint.style;
          that.tempPoint.html = that.formValidate.html;
        }

        if (that.markerTypeMark == '2') {
          that.point.image =
            'https://photo-sphere-viewer.js.org/assets/pin-red.png';
          // 'http://129.204.9.15:9024/data/wwwroot/joint_flight_java/upload_test/234/pin-red.png';
          that.point.markerTypeMark = '2';
          that.point.width = 32;
          that.point.height = 32;
          that.tempPoint = JSON.parse(JSON.stringify(that.point));
        }

        if (!data.rightclick) {
          that.markersPlugin.addMarker(that.point);

          that.markers.push(that.tempPoint);
        }
      });
    },
    init() {
      this.viewer = new Viewer({
        container: 'viewer',
        panorama: this.imgPath,
        loadingImg:
          'http://129.204.9.15:9024/data/wwwroot/joint_flight_java/upload_test/234/photosphere-logo.gif',
        touchmoveTwoFingers: true,
        mousewheelCtrlKey: true,
        caption: this.caption + '<b>&copy; bigDog</b>',
        defaultLong: '100deg',
        plugins: [MarkersPlugin],
      });

      this.markersPlugin = this.viewer.getPlugin(MarkersPlugin);

      this.handleViewerClick();
      this.handleMarkerClick();
    },
  },
};
</script>


<style lang="scss" scoped>
#viewer {
  width: 100%;
  height: 100%;
  /deep/ .guideIcon {
    width: 24px;
    height: 24px;
  }
  /deep/ .psv-panel-content {
    padding: 10px 20px 10px 10px;
    padding-right: 80px;
    line-height: 2em;
  }
}
.markContainer {
  position: fixed;
  right: 0;
  top: 40vh;
  z-index: 20;
  width: 60px;
  //   height: 331px;
  background: #333;
  text-align: center;
  .ico {
    color: #fff;
    border-bottom: 1px solid #000;
    display: block;
    padding: 8px 0;
    cursor: pointer;
  }
  .ico:first-child {
    background-color: #ed4014;
  }
  .ico:last-child {
    background-color: #19be6b;
  }
}
</style>
